@import "reset";
@import "descriptions";
@import "fonts";

html, body { width:100%; height: 100%; }

body {
    font-family: $font-family-gilroyextrabold;
    color: #696C7E;
    background: #ffffff;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

input, textarea {
    -webkit-appearance: none;
}
