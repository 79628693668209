.page {
  position: relative;
  min-height: 100%;

  &__middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    font-size: 20px;
    line-height: 24px;
    padding: 80px 40px;

    h2 {
      font-size: 36px;
      line-height: 44px;
      color: #2B2D3B;
      margin-top: 18px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    a {
      color: inherit;
    }
  }
}
